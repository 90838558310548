<template>
<section v-if="!requestPending && request" class="signup-section">
    <div class="container my-container">
        <div class="signup-content reg">
            <div class="reg-contianer">
                <h2 class="title">Reset Password</h2>
            </div>

            <form :class="{'register-form': true}">
                <div class="mb-2">
                    <label for="new-password" class="col-form-label">Password</label>
                    <input v-model="form.password" type="password" placeholder="Enter your new password" :class="formControl(v$.form.password)" id="new-password" required :disabled="loading">
                    <div v-if="v$.form.password.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.password.$errors[0].$validator, 'New password') }}</span>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="confirm-pass" class="col-form-label">Confirm Password</label>
                    <input v-model="form.confirm_password" type="password" placeholder="Re-enter your password" id="confirm-pass" :class="formControl(v$.form.confirm_password)" :disabled="loading">
                    <div v-if="v$.form.confirm_password.$error" class="invalid-feedback">
                        <span>{{ errorMessage(v$.form.confirm_password.$errors[0].$validator, 'Confirm password') }}</span>
                    </div>
                </div>
            </form>

            <div class="d-grid gap-2">
                <button type="button" class="btn btn-next-reg" @click="submit" :disabled="loading">Submit</button>
            </div>
        </div>
    </div>
</section>
<section v-if="!requestPending && !request" class="signup-section">
    <div class="container my-container">
        <div class="signup-content">
            <div class="success-submitted-container">
                <div class="msg-icon">
                <svg id="info-icon" xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114">
                    <path id="Path_79" data-name="Path 79" d="M56.846,0C88.358,0,114,25.642,114,56.846A57.223,57.223,0,0,1,56.846,114C25.642,114,0,88.358,0,56.846A57.093,57.093,0,0,1,56.846,0Zm2.472,21.935A9.3,9.3,0,0,1,68.585,31.2a9.5,9.5,0,0,1-9.268,9.268,9.268,9.268,0,0,1,0-18.537ZM72.6,84.341c-2.78,10.2-29.967,11.122-28.114-2.78,1.236-9.268,3.089-18.228,4.634-27.187.927-5.87-2.78-4.016-6.179-5.252-2.163-.927-2.163-3.707.618-4.325,3.4-.927,15.447-.309,19.463-.309,2.163,0,3.4,1.236,3.707,3.4a30.437,30.437,0,0,1-.618,5.252c-1.545,8.65-3.707,17.919-4.943,26.569,0,1.854.309,5.252,3.089,4.943,2.78,0,4.016-1.236,6.179-2.163C71.675,81.87,72.911,82.8,72.6,84.341Z" fill="#6c782a" fill-rule="evenodd"/>
                </svg>
                </div>
                <h4 v-if="!isSuccess" class="title-head">Password Reset</h4>
                <h4 v-else class="title-head">Success!!</h4>
                <p class="msg-normal-text">
                    {{ isSuccess ? 'Successfully updated your password!' : 'Your request is invalid or already expired!' }}
                    <br><br>
                    Meanwhile, please feel free to browse the website. Thank you.
                </p>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-ok" @click="$router.push('/')">Ok</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { useFind, useGet } from '@feathersjs/vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, defineComponent, getCurrentInstance } from 'vue';

export default defineComponent({
    data() {
        return {
            loading: false,
            isSuccess: false,
            form: {
                password: null,
                confirm_password: null
            }
        };
    },
    setup() {
        const vm = getCurrentInstance();
        const hashData = vm.appContext.app.config.globalProperties.$route.params.hashdata;
        const { ForgotPasswordRequest }  = vm.appContext.provides.$FeathersVuex.api;

        const request = useFind({ model: ForgotPasswordRequest, params: computed(() => {
            return { query: { status: 'Pending' } }
        }), fetchParams: computed(() => {
            return { query: { hash: hashData, status: 'Pending' } }
        }), immediate: true });

        const requestItem = useGet({ model: ForgotPasswordRequest, id: computed(() => {
            if(!request.isPending.value && request.items.value.length !== 0) {
                const expDate = new Date(request.items.value[0].exp_date).getTime() / 1000;
                const currDate = new Date().getTime() / 1000;
                if(expDate > currDate)
                    return request.items.value[0].id;
            }
            return 0;
        }), immediate: true });

        return {
            request: requestItem.item,
            requestPending: computed(() => request.isPending.value || requestItem.isPending.value),
            v$: useVuelidate(),
        };
    },
    validations () {
        return {
            form: {
                password: { required },
                confirm_password: { required, confirmPassword: this.confirmPassword }
            }
        };
    },
    methods: {
        async submit() {
            const currentKey = this.$route.params.hashdata;
            const isFormCorrect = await this.v$.$validate();
            if(!isFormCorrect) return;
            try {
                this.loading = true;
                this.request.key = currentKey;
                this.request.password = this.form.password;
                await this.request.patch();
                this.isSuccess = true;
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                this.loading = false;
            }
        },
        confirmPassword(value) {
            return value === this.form.password;
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        errorMessage(type, name) {
            switch(type) {
                case 'required': return `${name} is required`;
                case 'confirmPassword': return `Invalid confirmation password`;
            }
        }
    }
})
</script>
